import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Grid, Mousewheel} from "swiper"
import CategoriesBlogCard from "../Sliders/CategoriesBlogCard"
import "swiper/css"
import "swiper/css/grid"

const CategoriesContentSlider = ({categoryData}) => {
  SwiperCore.use([Grid, Mousewheel])
  const categoryItems = categoryData.allWpPost.nodes.map((itemCard, index) => {
    return(
      <SwiperSlide key={itemCard.id}>
          <CategoriesBlogCard
            postLink={itemCard.link}
            title={itemCard.title} 
            number={`${index+1}.`}
            imageSrc={itemCard.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          />
        </SwiperSlide>
    )
  })
  return (
    <>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        updateOnWindowResize={true}
        observer={true}
        direction={'vertical'}
        freeMode= {true}
        mousewheel={true}
        grabCursor={true}
        breakpoints={{
          900: {
            slidesPerView: 3,
            observer:true,
            updateOnWindowResize: true,
            slidesPerGroup: 1,
            speed: 600,
            grid: {
              fill: "row",
              rows: 2,
            },
            direction: "horizontal",
            spaceBetween: 60,
          },
        }}
        className="mySwiper projects_swiper"
      >
        {categoryItems}
        
      </Swiper>
    </>
  )
}

export default CategoriesContentSlider

