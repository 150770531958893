import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const CategoriesBlogCard = (props) => {
  return (
      <div className="main-slider_container">
        <a href={props.postLink}>
          <GatsbyImage style={{ width:"100%" , height: "100%"}} image={props.imageSrc} alt="img"/>
        <h2 className="projects-main_title">{props.title}</h2>
        </a>
      </div>
  )
}

export default CategoriesBlogCard
